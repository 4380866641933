import {api_get} from "./http";
import {getUrlParam} from "./utils";
import config from './config';
import cache from './cache';
export function checkIsWxBrowser(){
    var ua = navigator.userAgent.toLowerCase();
    return ua.match(/MicroMessenger/i) == "micromessenger";
}
export function initCode(){
    const code = getUrlParam('code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId

    let first_code=cache.get('first_code');

    const local = window.location.href
    if (code == null || code === '') {
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+config.wechat.AppId+'&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
    }
    // else if(first_code==code) {
    //     window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+config.wechat.AppId+'&redirect_uri=' + encodeURIComponent(local) + '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
    // }
    else{
        return new Promise((resolve, reject)=>{
            cache.set('first_code',code);
            get('/we_chat/checkWxCode',{code:code}).then(res=>{
                if(res.code==0){
                    resolve(res.data);
                }else{
                    reject(res.msg);
                }
            }).catch(err=>{
                reject(err);
            })
        } )
    }
}

