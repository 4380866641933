import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [

    {
        path: '/',
        name: 'home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
        children: [
            {
                path: '/withdraw',
                name: 'withdraw',
                component: () => import(/* webpackChunkName: "about" */ '../views/tabbar/withdraw.vue'),
                meta: {
                    title: '',
                    isFresh: true,
                }
            }, {
                path: '/user',
                name: 'user',
                component: () => import(/* webpackChunkName: "about" */ '../views/tabbar/user.vue'),
                meta: {
                    title: '',
                    isFresh: true,
                }
            }, {
                path: '/share_data',
                name: 'share_data',
                component: () => import(/* webpackChunkName: "about" */ '../views/tabbar/shareData.vue'),
                meta: {
                    title: '',
                    isFresh: true,
                }
            },
        ],
    }, {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/common/login.vue'),
        meta: {
            title: '',
            isFresh: true,
        }
    },{
        path: '/user/password/reset',
        name: 'user/password/reset',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/resetPassword.vue'),
        meta: {
            title: '',
            isFresh: true,
        }
    },{
        path: '/user/bankcard',
        name: 'user/bankcard',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/bankcard.vue'),
        meta: {
            title: '',
            isFresh: true,
        }
    },{
        path: '/chat',
        name: 'chat',
        component: () => import(/* webpackChunkName: "about" */ '../views/chat/chat.vue'),
        meta: {
            title: '',
            isFresh: true,
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
