export default {
    /**
     * 设置用户信息
     * @param state
     * @param data
     */
    setUserInfo(state,data){
        state.user_info=data;
        // console.log(state);
    },

    /*
    * 设置是否微信浏览器
    * @param state
    * @param data
    * */
    setWxBrowserBool(state,data){
        state.is_wx_browser=data;
    }



}