import {get,post} from "./http"
import {api_url} from './config'
import {checkCache} from "./utils"
import cache from "./cache"
import store from '@/store/index'

export function UserInit(){
    let token=cache.get('token');
    return new Promise(function(resolve, reject) {
        // 异步处理  // 处理结束后、调用resolve 或 reject
        if(checkCache(token)){ //如果token未过期
            refreshToken().then((info)=>{
                resolve(info);
            }).catch((err)=>{
                reject(err);
            })
        }else{
            reject(false);
        }
    });
}

export function getUserInfo(){
    return new Promise(function (resolve, reject) {
        get('/user_info/info',{}).then((res)=>{
            if(res.code==0){
                let token=cache.get('token');
                cache.set(token,res.data);
                store.commit('setUserInfo',res.data)
                resolve(res.data);
            }else{
                reject(false);
            }
        }).catch((err)=>{
            reject(err);
        })
    });
}
export function UpdateUserInfo(data){

}

export function refreshToken(){
    return new Promise(function (resolve, reject) {
        post('/user/checkToken',{}).then((res)=>{
            if(res.code==0){
                let token=cache.get('token');
                cache.set(token,res.data);
                store.commit('setUserInfo',res.data)
                resolve(res.data);
            }else{
                reject(false);
            }
        }).catch((err)=>{
            reject(err);
        })
    });
}


